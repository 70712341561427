.wrapper {
  margin: 0 auto;
  display: grid;
  /* width: 90%; */
  /* grid-template-columns: repeat(14, 7%);
  grid-template-rows: repeat(6, 16%); */
  
  /* width: 90%;
  min-height: 70vh; */
}
/* .item1 {
  grid-column: 1 / 7;
  grid-row: 1 / 7;
}
.item2 {
  grid-column: 7 / 10;
  grid-row: 1 / 4;
}
.item3 {
  grid-column: 7 / 10;
  grid-row: 4 / 7;
}
.item4 {
  grid-column: 10 / 13;
  grid-row: 1 / 4;
}
.item5 {
  grid-column: 10 / 13;
  grid-row: 4 / 7;
}
.item6 {
  grid-column: 13 / 15;
  grid-row: 1 / 3;
}
.item7 {
  grid-column: 13 / 15;
  grid-row: 3 / 5;
}
.item8 {
  grid-column: 13 / 15;
  grid-row: 5 / 7;
} */

.topRow {
  display: flex;
  justify-content: center;
}

.user {
  background-color: rgb(0, 0, 0);
  margin: 0 5px 5px 0;
  max-height: 100%;
  max-width: 100%;
}

.user div:first-child {
  width: 100%;
  height: 100%;
  position: relative;
}

.bottomRow {
  display: flex;
  justify-content: center;
}
  
  .wrapper {
    grid-template-rows: repeat(4, 1fr);
    max-width: 47%;
    margin-top: .5rem;
  }
  .item1 {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
  }
  .item2 {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
  }
  .item3 {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
  }
  .item4 {
    grid-column: 4 / 5;
    grid-row: 3 / 4;
  }
  .item5 {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }
  .item6 {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }
  .item7 {
    grid-column: 3 / 4;
    grid-row: 4 / 5;
  }
  .item8 {
    grid-column: 4 / 5;
    grid-row: 4 / 5;
  }



@media (max-width: 1400px) { 
  .wrapper {
    grid-template-rows: repeat(4, 1fr);
    width: 100%;
    max-width: 90%;
  }
}

@media (max-width: 960px) { 
  .wrapper {
    height: 55vh;
    /* width: 55% !important; */
  }
}