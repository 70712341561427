.wrapper {
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px 0;
  opacity: 0.8;
}

.wrapper:hover {
  margin: 0 auto;
  background-color: rgb(59, 59, 59);
  padding: 20px 0;
}

.wrapper:hover .user,
.wrapper:hover .mainUser,
.wrapper:hover .screen {
  background-color: #ffffff;
}

.topRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user {
  width: 26px;
  height: 19px;
  background-color: rgb(59, 59, 59);
  margin: 0 5px 5px 0;
}

.mainUser {
  background-color: rgb(59, 59, 59);
  width: 65px;
  height: 45px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.screen {
  background-color: rgb(59, 59, 59);
  width: 70px;
  height: 50px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.bottomRow {
  display: flex;
  justify-content: center;
}
