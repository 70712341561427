.wrapper {
}

.wrapper video {
  width: 100%;
  cursor: initial;
}

.descriptionWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 50;
}

.descriptionMain {
  font-size: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #ffffff;
  align-items: flex-start;
  padding-left: 10px;
}

.description {
  font-size: 10px;
  color: #ffffff  !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fillScreenMain {
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 20px !important;
}

.fillScreenMain:hover {
  transform: scale(1.1);
}

.fillScreen {
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 12px !important;
}

.fillScreen:hover {
  transform: scale(1.2);
}

.listMain {
  cursor: pointer;
  position: absolute;
  right: 30px;
  bottom: 10px;
  width: 20px !important;
}

.listMain:hover {
  transform: scale(1.1);
}

.list {
  cursor: pointer;
  position: absolute;
  right: 21px;
  bottom: 10px;
  width: 12px !important;
}

.list:hover {
  transform: scale(1.2);
}

.home {
  cursor: pointer;
  position: absolute;
  right: 37px;
  bottom: 10px;
  width: 12px !important;
}

.home:hover {
  transform: scale(1.2);
}

.microphone {
  cursor: pointer;
  position: absolute;
  right: 53px;
  bottom: 10px;
  width: 20px !important;
}

@media (max-width: 960px) {

  .microphone, .fillScreen, .home, .list {
    bottom: 9px;
  }

  .descriptionMain {
    font-size: 12px;
    margin-top: -50px;
  }

  .descriptionWrapper {
    bottom: 0;
  }
}