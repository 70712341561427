.streamcomponent {
  /* width: 100%; */
}

.streamcomponentMain {
  width: 23%;
}

.streamcomponent h2 {
  font-size: 10px;
}

.streamcomponent p {
  font-size: 10px;
  text-align: center;
}

.myTag h2 {
  color: #ffffff;
  font-size: 12px;
}

.myTagMain h2 {
  margin-right: 5px;
  font-size: 14px;
  word-break: break-all;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}

.myTag img {
  cursor: pointer;
}

.myTagMain img {
  cursor: pointer;
}

.myTag img:hover {
  transform: scale(1.1);
}

.myTagMain img:hover {
  transform: scale(1.1);
}

.myTagMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tagChangeWrapper {
  height: 15px;
  text-align: center;
  margin-bottom: 15px;
}

.tagChangeWrapperMain {
  height: 15px;
  text-align: center;
  margin-bottom: 15px;
}

.tagChangeWrapper input {
  font-weight: 600;
  color: #002f2f;
  border: none;
  width: 200px;
  height: 30px;
  border-radius: 3px;
  outline: none;
  font-size: 16px;
  padding-left: 5px;
}

.tagChangeWrapperMain input {
  font-weight: 600;
  color: #002f2f;
  border: none;
  width: 200px;
  height: 30px;
  border-radius: 3px;
  outline: none;
  font-size: 16px;
  padding-left: 5px;
}

.streamcomponent p {
  font-size: 15px;
  text-align: center;
}

.streamcomponentMain p {
  font-size: 15px;
  text-align: center;
}

.videoComponent {
  padding: 4px 2px;
}

.videoComponent video {
  height: 100%;
}

.edit {
  width: 1rem;
}

@media (max-width: 960px) {
  .myTagMain h2 {
    font-size: .8rem;
  }

  .edit {
    width: 1rem;
  }
}