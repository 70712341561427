.mains {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1230px;
  width: 100%;
  margin-top: .5rem;
}

.user {
  width: 300px;
  height: 225px;
  background-color: rgb(0, 0, 0);
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 960px) {
  .user {
    width: calc(90vw / 4);
    height: 150px;
    overflow: hidden;
  }
}
