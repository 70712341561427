.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.wrapper video {
  max-width: 100%;
  max-height: 100%;
  cursor: initial;
}

.descriptionWrapper {
  position: relative;
  position: absolute;
  bottom: 6px;
  height: 25%;
  width: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 50;
  display: flex;
  justify-content: space-around;
}

.descriptionMain {
  font-size: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.description {
  font-size: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fillScreenMain {
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 20px !important;
}

.fillScreenMain:hover {
  transform: scale(1.1);
}

.fillScreen {
  cursor: pointer;
  right: 5px;
  bottom: 10px;
  width: 20px !important;
  height: 20px;
}

.fillScreen:hover {
  transform: scale(1.2);
}

.home {
  cursor: pointer;
  right: 21px;
  bottom: 10px;
  width: 20px !important;
  height: 20px;
}

.home:hover {
  transform: scale(1.2);
}

.listMain {
  cursor: pointer;
  right: 30px;
  bottom: 10px;
  width: 20px !important;
}

.listMain:hover {
  transform: scale(1.1);
}

.list {
  cursor: pointer;
  right: 37px;
  bottom: 10px;
  width: 20px !important;
  height: 20px;
}

.list:hover {
  transform: scale(1.2);
}

.microphone {
  cursor: pointer;
  right: 53px;
  bottom: 10px;
  width: 20px !important;
  height: 20px;
}
