.container {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.container img {
  width: 80px;
}

.subscribers {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.subscribers div {
  /* margin-left: 5px; */
  color: #1f1f1f;
}

.streamContainer {
  width: 150px;
  margin: 0 .2rem;
}

@media (max-width: 960px) {
  .container {
    margin-top: 0;
  }
  .streamContainer {
    width: 110px;
  }
}
