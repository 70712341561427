.wrapper {
  margin: 5px auto 0 ;
  width: 90vw;
}

.topRow {
  display: flex;
  justify-content: center;
}

.user {
  width: calc(100vw/7 - 9px);
  height: calc(35vw/4);
  background-color: rgb(0, 0, 0);
  margin: 0 5px 5px 0;
  position: relative;
  overflow: hidden;

}
.mainUser {
  background-color: rgb(0, 0, 0);
  width: calc(100vw/3);
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #000;
  position: relative;
}

.bottomRow {
  display: flex;
  justify-content: center;
}
