.wrapper {
  color: #ffffff;
  font-weight: 500;
  font-size: 26px;
  position: fixed;
  bottom: 10px;
  right: 50px;
  user-select: none;
  cursor: pointer;
}


.listWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: 6rem;
  height: 100%;
  max-height: 300px;
  width: 180px;
  background-color: rgba(0, 0, 0, .5);
  overflow-y: auto;
}

.listItem {
  display: block;
  padding: .2rem;
}

.listItem span {
  font-size: 14px;
}