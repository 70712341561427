.wrapper {
  width: 300px;
  height: 500px;
  overflow-y: auto;
  background-color: #ececec;
  opacity: 0.8;
  border-radius: 5px;
  color: #000000;
  padding: 10px;
}

.wrapper h1 {
  font-size: 16px;
  text-decoration: underline;
  margin-bottom: 10px;
  font-weight: 500;
}

.wrapper div {
  border-radius: 3px;
  padding: 6px;
  color: #000000;
}

.wrapper div:hover {
  background-color: rgb(96, 78, 255);
  color: #ffffff;
  cursor: pointer;
}
