.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  height: auto;
  height: 100%;
  width: 100%;
}

.wrapper video {
  max-height: 100%;
  max-width: 100%;
  cursor: initial;
}

.descriptionWrapper {
  position: relative;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 50;
}

.descriptionMain {
  font-size: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.description {
  font-size: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 5px;
}

.fillScreenMain {
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 20px;
}

.fillScreenMain:hover {
  transform: scale(1.1);
}

.fillScreen {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 8px;
  width: 20px !important;
}

.fillScreen:hover {
  transform: scale(1.2);
}

.home {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 35px !important;
  width: 20px !important;
}

.home:hover {
  transform: scale(1.2);
}

.listMain {
  cursor: pointer;
  position: absolute;
  right: 30px;
  bottom: 10px;
  width: 20px;
}

.listMain:hover {
  transform: scale(1.1);
}

.list {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 58px !important;
  width: 20px !important;
}

.list:hover {
  transform: scale(1.2);
}

.microphone {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 80px !important; 
  width: 20px !important;
}

.microphone:hover {
  transform: scale(1.1);
}

@media (min-width: 1400px) {
  .description {
    font-size: 16px;
  }

  .home {
    right: 50px !important;
    width: 25px !important;
  }

  .fillScreen {
    right: 10px !important;
    width: 25px !important;
  }

  .microphone {
  right: 120px !important; 
  width: 25px !important;
}

.list {
  right: 87px !important;
  width: 25px !important;
}
}