.wrapper {
  /* position: relative; */
}

.wrapper video {
  width: 100%;
  cursor: initial;
}

.descriptionWrapper {
  position: relative;
  position: absolute;
  bottom: 0;
  /* left: 0; */
  width: 100%;
  height: 25%;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0.5;
  z-index: 50;
  display: flex;
  display: block;
  justify-content: space-between;
  align-items: flex-end;
}

.main {
  height: 10%;
}

.descriptionMain {
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.description {
  font-size: 16px;
  display: flex;  
  margin-top: -69px;
  flex-direction: column;
  padding-left: 5px;
  padding-bottom: 10px;
}

.description p {
  margin-right: 5px;
}

.fillScreenMain {
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 20px !important;
}

.fillScreenMain:hover {
  transform: scale(1.1);
}

.fillScreen {
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 16px !important;
}

.fillScreen:hover {
  transform: scale(1.2);
}

.listMain {
  cursor: pointer;
  position: absolute;
  right: 35px;
  bottom: 10px;
  width: 16px !important;
}

.listMain:hover {
  transform: scale(1.1);
}

.home {
  cursor: pointer;
  position: absolute;
  right: 30px;
  bottom: 10px;
  width: 16px !important;
}

.home:hover {
  transform: scale(1.2);
}

.list {
  cursor: pointer;
  position: absolute;
  right: 53px;
  bottom: 10px;
  width: 16px !important;
}

.list:hover {
  transform: scale(1.2);
}

.microphone {
  cursor: pointer;
  position: absolute;
  right: 75px;
  bottom: 10px;
  width: 16px !important;
}


@media (min-width: 1400px) {
  .fillScreen {
    cursor: pointer;
    position: absolute;
    right: 5px;
    bottom: 10px;
    width: 28px !important;
  }

  .fillScreenMain {
    width: 28px !important;
  }

  .home {
    cursor: pointer;
    position: absolute;
    right: 45px;
    bottom: 10px;
    width: 28px !important;
  }

  .list {
    cursor: pointer;
    position: absolute;
    right: 80px;
    bottom: 10px;
    width: 28px !important;
  }

  .microphone {
    cursor: pointer;
    position: absolute;
    right: 110px;
    bottom: 10px;
    width: 28px !important;
  }
}

@media (max-width: 960px) {

  .microphone, .fillScreen, .home, .list {
    bottom: 3px;
  }

  .description {
    font-size: 12px;
    margin-top: -50px;
  }
}