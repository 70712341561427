
.joinSubmit {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  outline: none;
  background-color: #002f2f;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  width: 160px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.joinDialog {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 150px auto 0;
  padding: 0 1rem 1rem;
}

.formBlock {
  width: 70%;
  max-width: 800px;
}

.joinDialog p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.joinDialog h2 {
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 1rem;
  text-align: right;
}

.labels {
  width: 20%;
  min-width: 150px;
  font-weight: 500;
  font-size: 16px;
}

.formControl {
  padding: 0 10px;
  color: #0088aa;
  font-weight: bold;
  height: 35px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  outline: none;
  border: none;
}

.item1 {
  width: 93%;
  margin-right: .5rem;
}

.copyButton {
  width: 36px;
  height: 36px;
  background-color: rgba(13, 90, 153, 0.397);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.copyButton:active {
  background-color: orange;
  transform:scale(0.8);
}

.copyButton img {
  width: 30px;
  height: 30px;
  padding: 5px;
}

.copyButton::after {
  content: attr(data-title);
  position: absolute;
  top: -50px;
  left: 5px;
  display: none;
}

.copyButton:hover::after {
  display: block;
}

.copyButton:active::after {
  display: none;
}

.formControl:focus {
  border-color: #0088aa;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 136, 170, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 136, 170, 0.6);
}

.info {
  background-color: rgba(139, 0, 0, 0.952);
  font-size: 1rem;
  text-align: center;
  padding: .8rem 1rem;
  border-radius: .8rem;
}

@media (max-width: 975px) {
  
  .joinDialog {
    margin: 0 auto 0;
  }
  
  .joinDialog h2 {
    font-size: 1.2rem;
  }

  .formBlock {
    width: 100%;
    max-width: 800px;
  }
}

@media (max-width: 560px) {
  .joinDialog {
    margin: 10px 0;
  }
  .joinDialog {
    width: 100%;
  }
  .joinDialog h2 {
    text-align: center;
  }
  .joinDialog p {
    width: 100%;
  }
  .labels {
    display: none;
  }
  .formControl {
    width: inherit;
  }
  .joinSubmit {
    width: 100%;
  }
  .info {
    font-size: 12px;
    padding: .6rem .3rem;
  }
  .joinDialog h2 {
    font-size: 1rem;
  }
  .formBlock {
    width: 100%;
  }
}