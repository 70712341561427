.wrapper {
  position: fixed;
  top: 0;
  padding-top: 10vh;
  padding-left: 55vw;
  z-index: 1000;
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
}
.chat {
  width: 600px;
  padding-top: 20px;
  background-color: #d4d4d4;
  color: #000000;
  height: 80vh;
  border-radius: 5px;
}

.messages {
  height: 75vh;
  overflow-y: auto;
}

.myMessage {
  position: relative;
  border: 2px solid #ff6f6f;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 300px;
  margin-left: auto;
  font-size: 16px;
  margin-right: 5px;
  margin-bottom: 25px;
}

.myName {
  position: absolute;
  right: 5px;
  bottom: -25px;
  font-weight: 500;
  color: #272727;
}

.myTime {
  text-align: right;
  font-size: 14px;
}

.message {
  position: relative;
  border: 2px solid #8771ff;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 300px;
  margin-left: 5px;
  margin-bottom: 30px;
}

.name {
  position: absolute;
  left: 5px;
  bottom: -25px;
  font-weight: 500;
  color: #272727;
}

.time {
  text-align: right;
  font-size: 14px;
}

.input {
  margin-top: auto;
  width: 100%;
  outline: none;
  border: none;
  height: 5vh;
  padding: 0 10px;
  font-size: 20px;
}
