.wrapper {
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px 0;
  opacity: 0.8;
}

.wrapper:hover {
  margin: 0 auto;
  background-color: rgb(59, 59, 59);
  padding: 20px 0;
}

.wrapper:hover .user,
.wrapper:hover .mainUser {
  background-color: #ffffff;
}

.topRow {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 165px;
  margin: 0 auto;
}

.user {
  width: 26px;
  height: 19px;
  background-color: rgb(59, 59, 59);
  margin: 0 5px 5px 0;
}

.mainUser {
  background-color: rgb(59, 59, 59);
  width: 35px;
  height: 25px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.bottomRow {
  display: flex;
  justify-content: center;
}
