.foolScreen img:first-child {
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 50px;
  width: 50px !important;
  z-index: 100;
}

.foolScreen img:hover {
  transform: scale(0.9);
}

.foolScreen {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.98);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.foolScreen video {
  height: 70%;
}

.subscribersBlock {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}



