.wrapper {
  width: 100%;
}

.topRow {
  width: 100%;
  height: 100%;
}

.mainUser {
  background-color: rgb(0, 0, 0);
  /* width: 90vw; */
  max-height: 50vh;
  margin: .5rem auto 0;
}

.user {
  background-color: rgb(0, 0, 0);
  margin: 3px;
  height: 13vh;
}

.mainUser div:first-child {
  width: 100%;
  height: 100%;
}

.user div:first-child {
  width: 100%;
  height: 100%;
}

.bottomRow {
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
}

