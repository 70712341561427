.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 5px;
  padding-right: 10px;
  z-index: 1001;
  margin: 0 auto;
  /* background-color: #1f1f1f; */
  /* position: absolute; */
  /* top: 120px;
  left: 50%;
  transform: translateX(-50%); */
  /* width: 220px; */
}

.wrapper img {
  width: 40px !important;
  cursor: pointer;
  margin-left: 20px;
}

.positionDown {
  top: 10px;
}

@media screen and (max-width: 560px) {
  .wrapper {
    top: 50px;
  }
}