.wrapper {
  position: relative;
  /* position: absolute; */
  /* top: 150px; */
  width: 75px;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer; 
  margin: 0 .5rem;
}

.squares {
  width: 35px;
  height: 35px;
  background-color: rgb(128, 128, 128);
  opacity: 0.5;
}

.select {
  position: absolute;
  left: -28px;
  top: 20px;
  width: 230px;
  transform: scale(0.75);
  z-index: 9000;
}
