.OvVideoContainerMain,
.OvVideoContainerPublisherMain,
.OvVideoContainerPublisherOnlyMain {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  background-image: url('../../../img/oneLayer.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: center;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
}

.OvVideoContainerPublisherMain {
  transform: rotateY(8deg);
  -webkit-transform: rotateY(8deg);
  -moz-transform: rotateY(8deg);
  max-width: 100%;
  height: 22vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

.OvVideoContainerMain {
  transform: rotateY(-8deg);
  -webkit-transform: rotateY(-8deg);
  -moz-transform: rotateY(-8deg);
  max-width: 100%;
  height: 22vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

.OvVideoContainerMain video,
.OvVideoContainerPublisherMain video,
.OvVideoContainerPublisherOnlyMain video {
  max-width: 100%;
  max-height: 100%;
  cursor: initial;
}

.OvVideoContainer {
  position: relative;
  /* margin-left: 10px;fwidth: 150px; */
}

.OvVideoContainer video {
  width: 150px;
  cursor: initial;
  height: 120px;
  background-color: #000;
}

.OvVideoContainer img {
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 10px !important;
}

.OvVideoContainer img:hover {
  transform: scale(1.1);
}

.OvVideoContainerSahreScreen {
  min-width: 40%;
  min-height: calc(100vw / 7);
  max-width: 40%;
  background-color: #000000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}

.OvVideoContainerSahreScreen img {
  cursor: pointer;
  position: absolute;
  right: 20px;
  bottom: 10px;
  width: 20px !important;
}

.OvVideoContainerSahreScreen img:last-child{
  cursor: pointer;
  position: absolute;
  right: 50px;
  bottom: 10px;
  width: 20px !important;
}


.OvVideoContainerSahreScreen video {
  max-width: 100%;
  max-height: 100%;
}

.microphone {
  cursor: pointer;
  position: absolute;
  right: 20px !important;
  bottom: 10px;
  width: 10px;
}

@media (min-width: 1360px) {
  .OvVideoContainer img {
    cursor: pointer;
    position: absolute;
    /* right: 5px; */
    bottom: 10px;
    width: 18px !important;
  }
  .microphone {
    margin-right: 10px;
    /* bottom: 10px; */
  }
}

@media (max-width: 960px) {
  .OvVideoContainer video {
    width: 110px;
  }
}