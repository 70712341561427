.container {
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}

header {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo {
  max-width: 200px;
}

.conferenceHead {
  text-align: center;
  position: relative;
}

.conferenceHead h2 {
  font-weight: 500;
  letter-spacing: 0.8px;
}

.conferenceHead h1 {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0.8px;
}

/* .images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.left {
  width: 550px;
  text-align: center;
}

.right {
  width: 550px;
  text-align: center;
}

.leftLayer {
  height: 450px;
  background-image: url('./img/leftLayer.png');
  background-size: cover;
}

.rightLayer {
  height: 450px;
  background-image: url('./img/rightLayer.png');
  background-size: cover;
}

.logos {
  width: 90px;
}

@media (max-width: 895px) {
  .header {
    padding: 0 20px;
  }
}

@media (max-width: 960px) {

  .logos {
    max-width: 160px;
  }

  .logo {
    max-width: 150px;
  }

  .conferenceHead h1 {
    font-size: 1.6rem;
  }

  .conferenceHead {
    padding-bottom: 1rem;
  } 

  .conferenceHead h2 {
    font-size: 1.2rem;
  }

  header {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
}

@media (max-width: 560px) {
  .container {
    padding: 0;
    margin: 0 0 3rem;
    width: 100%;
  }
  .header {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .images {
    display: none;
  }
  .conferenceHead h1 {
    font-size: 1rem;
    padding: .5rem;
  }
  .conferenceHead h2 {
    font-size: .7rem;
  }
  .logo {
    max-width: 90px;
    display: none;
  }
  header {
    padding-bottom: 0;
  }
}

.version {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}