.wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 0 10px;
  width: 100%;
  height: 46vh;
  perspective: 1000px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  margin-top: 4rem;
}

.topicTitle {
  width: 100%;
  text-align: center;
  padding-top: 15px;
  font-size: 20px;
}

@media (max-width: 960px) {
  .wrapper {
    margin-top: 2rem;
    height: 38vh;
    align-items: flex-end;
  }
}