/* .foolScreen {
  position: relative;
} */

.foolScreen {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.98);
  display: flex;
  justify-content: center;
  z-index: 250;
}

.foolScreen video {
  height: 100%;
}

.foolScreen img {
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 50px;
  width: 50px !important;
  z-index: 100;
}

.foolScreen img:hover {
  transform: scale(0.9);
}

@media screen and (max-width: 560px) {
  .foolScreen img {
    right: 20px;
    top: 20px;
    width: 30px !important;
  } 
}
